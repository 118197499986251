var prodcat;

prodcat = prodcat || {};

(function ($) {
  Drupal.behaviors.addAllToBagFormatterV1 = {
    attach: function () {
      var self = this;
      var $modules = $('.js-add-all-to-bag-formatter', document);

      $modules.each(function () {
        var $module = $(this);

        self.getDom($module);
        self.calculatePrice($module);
        self.updateLabel($module);
        self.setupAddAllToBag($module);
      });
    },
    getDom: function ($module) {
      var $local = $module;

      $local.nodes = {};
      $local.nodes.cta = $('.js-add-all-to-bag-formatter-cta', $local);
      $local.nodes.ctaBtn = $('.js-elc-button', $local.nodes.cta);
      $local.nodes.content = $('.js-add-all-to-bag-formatter-content', $local);
      $local.nodes.productBriefs = $('.js-product-brief', $local.nodes.content);
      $local.config = {};
      $local.config.allPrice = '';
    },
    calculatePrice: function ($module) {
      var prices = [];
      var overlayPrice;
      var totalPrice;
      var $local = $module;

      $local.nodes.productBriefs.each(function () {
        var skuId = $(this).attr('data-sku-base-id');
        var formattedPrice;
        var pricewithTax;

        totalPrice = Drupal.settings.display_totalprice_withtax
          ? Drupal.settings.display_totalprice_withtax
          : false;
        if (totalPrice) {
          formattedPrice = prodcat.data.getSku(skuId).formattedPrice;
          pricewithTax = parseFloat(formattedPrice.replace(/\$|,/gu, '.'));
          prices.push(pricewithTax);
        } else {
          prices.push(prodcat.data.getSku(skuId).PRICE);
        }
      });
      overlayPrice = Drupal.settings.trending_look_overlay_price
        ? Drupal.settings.trending_look_overlay_price
        : false;
      if (overlayPrice) {
        $local.config.allPrice = prices.reduce((price1, price2) => price1 + price2);
      }
      $local.config.allPrice = prices.reduce((price1, price2) => price1 + price2).toFixed(2);
    },
    updateLabel: function ($module) {
      var labelInput = $module.nodes.ctaBtn.find('span');

      if (labelInput) {
        $module.nodes.ctaBtn.html(labelInput.html().replace('::count::', $module.nodes.productBriefs.length).replace('::price::', $module.config.allPrice));
      }
    },
    setupAddAllToBag: function ($module) {
      var allSkuIds = [];

      $module.nodes.productBriefs.each(function () {
        var $productBrief = $(this);
        var $shadeSelected = $productBrief.find('.js-product-brief-shades-item-link.selected');
        var $targetSkuElement = $shadeSelected.length > 0 ? $shadeSelected : $productBrief;

        allSkuIds.push($targetSkuElement.attr('data-sku-base-id'));
      });

      $module.nodes.ctaBtn.attr('data-sku-base-id', allSkuIds.join(', ')).addClass('product-add-all-to-bag js-product-add-all-to-bag');
    }
  };
})(jQuery);
